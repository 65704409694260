import React, { useState, useRef, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import "../blogPost/index.css";
import "../navbar.css";
import { categories } from "./categoryList";
import { useDispatch, useSelector } from "react-redux";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { useParams } from "react-router";
import { SlArrowUp } from "react-icons/sl";
import tooltipMenue from "../Assets/Blog/image 12.svg";
import Add from "../Assets/Blog/Add.svg";
import Editor from "./Editor/Editor";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import BottonLoader from "../../utils/BottonLoader";
import EditorTextParser from "./Editor/Editor-parser/Editor_parser";
// import exampleData from "./Editor/data/Data";
import {
  setData,
  setTitle,
  setCoverPhotoUrl,
  setCategories,
  fetchPostById,
  setUpdatePost,
} from "../Slices/Post.slice";
import { logoutUser } from "../Slices/User.slice";
//nav
import { useData } from "../Preview/GetData";
import createLogo from "../Assets/Blog/Layer 2.svg";
import userPic from "../Assets/Blog/Ellipse 1.svg";
import NavProfile from "../Assets/Blog/profile.svg";
import navBlog from "../Assets/Blog/Blog (1).svg";
import navStat from "../Assets/Blog/Stats.svg";
import { FaEdit } from "react-icons/fa";
import navSignout from "../Assets/Blog/log out.svg";
import { SlArrowDown } from "react-icons/sl";
import { Preview } from "../Preview/Preview";
import { current } from "@reduxjs/toolkit";
import { useForm } from "react-hook-form";
import Loader from "../../utils/Loader";
import { farmsellApi } from "../../Api/famsellAPi";
const NavBlog = () => {
  const dispatch = useDispatch();
  const profilepic = useSelector((state) => state.users.userProfilePic);
  const currentUser = useSelector((state) => state.users?.user?.user);
  const [showProfileMenu, setShowProfileMenu] = useState(false);
  const navigate = useNavigate();
  const toggleProfileMenu = () => {
    setShowProfileMenu(!showProfileMenu);
  };
  console.log(profilepic);

  const username = useSelector((state) => state.users.user?.user?.name);

  return (
    <div className="blog-nav-wrapper">
      <div className="nav-logo">
        <img
          src={createLogo}
          onClick={() => {
            navigate("/");
            dispatch(logoutUser(null));
          }}
          alt=""
        />
        <button className="create-nav-blog">Blog</button>
      </div>
      <div>
        <div className="navbar-profile">
          <img
            src={
              currentUser && currentUser.UserPic !== null
                ? currentUser.UserPic
                : userPic
            }
            alt="user profile"
            onClick={() => navigate("/user")}
            style={{  objectFit: 'cover', borderRadius: '50%' }}   
           
          />
          <span>{currentUser?.name}</span>
          <span onClick={toggleProfileMenu}>
            {showProfileMenu ? <SlArrowUp /> : <SlArrowDown />}
          </span>
        </div>

        {showProfileMenu && (
          <div className="profile-menu">
            <ul>
              <li onClick={() => navigate("/user")}>
                <img src={NavProfile} alt="nav profile" />
                <p>Profile</p>
              </li>
              <li onClick={() => navigate("/blogs")}>
                <img src={navBlog} alt="nav blog" />
                <p>Blogs</p>
              </li>
              <li onClick={() => navigate("/stat")}>
                <img src={navStat} alt="nav statistics" />
                <p>Statistics</p>
              </li>
            </ul>
            <button onClick={() => dispatch(logoutUser(null))}>
              <img src={navSignout} alt="sig out" />
              <span>Sign out</span>
            </button>
          </div>
        )}
      </div>
    </div>
  );
};
export default function Index() {
  const serverDomain = process.env.REACT_APP_SERVER_DOMAIN;
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [uploading, setUploading] = useState(false);
  const { data, setData } = useData();
  console.log(data);
  const [openPreview, setPreview] = useState(false);
  const [openCategory, setCategory] = useState(false);
  const [openPublish, setPublish] = useState(false);
  const [openComment, setComment] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [posttitle, setPosttitle] = useState("");
  const [value, onChange] = useState(new Date());
  const postsdata = useSelector((state) => state.posts.singlepost);
  const titl = postsdata ? postsdata.title : null;
  const postData = postsdata ? JSON.parse(postsdata.data) : null;
  const titlep = useSelector((state) => state.posts.title);
  const title = titl ? titl : titlep;
  const coverImg = useSelector((state) => state.posts.coverPhotoUrl);
  const exampleData = useSelector((state) => state.posts.data);
  const dispatch = useDispatch();
  const [searchItem, setSearchItem] = useState("");
  const [uploadedPhoto, setUploadedPhoto] = useState(null);
  const [openShedule, setOpenSchudule] = useState();
  const username = useSelector((state) => state.users.user?.user?.name);
  const loading = useSelector((state) => state.users.loading);
  const updatePost = useSelector((state) => state.posts.updatePost);
  const PostLoading = useSelector((state) => state.posts.postLoading);
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState([]);
  const notify = () => toast("Success!");
  const [filteredCategories, setFilteredCategories] = useState([]);
  const categoriesList = [
    "Tech",
    "Health",
    "Finance",
    "Education",
    "Education",
    "Education",
    "Education",
    "Education",
    "Education",
    "Education",
  ];

  //logic for the category search
  const handleCategoryChange = (e) => {
    const term = e.target.value;
    setSearchItem(term);
  };
  useEffect(() => {
    if (searchItem.length > 0) {
      const filtered = categories.filter((category) =>
        category.toLowerCase().startsWith(searchItem.toLowerCase())
      );
      setFilteredCategories(filtered);
      // Show dropdown when typing
    } else {
      // Hide dropdown when input is cleared
    }
  }, [searchItem]);
  console.log({
    searchItem,
    filteredCategories,
    selectedCategory,
  });
  const handleAddCategoryClick = () => {
    console.log("Clicked");
    if (categories.includes(searchItem)) {
      setSelectedCategory((prev) =>
        prev.includes(searchItem) ? prev : [...prev, searchItem]
      );
      setSearchItem(""); // Clear the input after adding
      setFilteredCategories([]);
      // Clear filtered categories
    } else {
    }
  };
  const handleSuggestionClick = (category) => {
    // Add selected category from the suggestion list
    if (!selectedCategory.includes(category)) {
      setSelectedCategory((prev) => [...prev, category]);
    }
    setSearchItem(""); // Clear the input
    setFilteredCategories([]); // Clear the suggestions
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0]; // Get the first file selected by the user
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        uploadFile(file); // Update the state with the base64 encoded image URL
      };
    }
  };

  const { postid } = useParams();
  useEffect(() => {
    // Fetch post data by ID when component mounts
    dispatch(fetchPostById(postid));
    if (postid) {
      dispatch(setUpdatePost(true));
    }
  }, [dispatch, postid]);



  const firstHeaderBlock = data ? data.blocks.find(block => block.type === "header"):"";

  const HeaderTitle = firstHeaderBlock? firstHeaderBlock.data.text:"" || "no title"
  useEffect(()=>{
    // setPosttitle();
    dispatch(setTitle(HeaderTitle));
  },[data])
  const uploadFile = (file) => {
    setUploading(true);
    const formData = new FormData();
    formData.append("file", file);
    farmsellApi.post("/upload", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        console.log(response.data.paths[0]);
        const imageUrl = response.data.paths[0];
        dispatch(setCoverPhotoUrl(`${serverDomain}${imageUrl}`));
        setUploadedPhoto(`${serverDomain}${imageUrl}`);
        setUploading(false);
        notify();
      })
      .catch((error) => {
        setUploading(false);
        console.error("Error uploading file:", error);
      });
  };

  const handtitleChange = (e) => {
    
  };

  const ScheduleList = [
    {
      name: "Automatic",
    },
    {
      name: "Set date and time",
    },
  ];
  console.log(postsdata);
  const Checkbox = ({ isChecked, label, checkHandler, index }) => {
    return (
      <div className="categories-ul-li">
        <input
          type="checkbox"
          id={`checkbox-${index}`}
          checked={isChecked}
          onChange={checkHandler}
        />
        <label htmlFor={`checkbox-${index}`}>{label}</label>
      </div>
    );
  };
  
  const [checkedState, setCheckedState] = useState(categoriesList);
  console.log(checkedState);
  const handleOnChecked = (index) => {
    setCheckedState(
      checkedState.map((category, currentIndex) =>
        currentIndex === index
          ? { ...category, checked: !category.checked }
          : category
      )
    );
  };
  const selectedCategoryNames = checkedState
    .filter((category) => category.checked)
    .map((category) => category.name);
  dispatch(setCategories(selectedCategoryNames));
  console.log(selectedCategoryNames);
  const [selectedOption, setSelectedOption] = useState("automatic");

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };
  const [commentOption, setCommentOption] = useState("automatic");

  const handleCommentChange = (event) => {
    setCommentOption(event.target.value);
  };
  const [selectedDate, setSelectedDate] = useState(new Date());

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };
  const handleClick = (event) => {
    setCategory((current) => !current);
  };
  const handleClickSchudule = (event) => {
    setOpenSchudule((current) => !current);
  };
  function toggleEditMode() {
    if (isEditMode) {
      setIsEditMode(false);
      console.log("Edit mode is now disabled");
    } else {
      setIsEditMode(true);
      console.log("Edit mode is now enabled");
    }
  }
  const handleUploadButtonClick = () => {
    fileInputRef.current.click();
  };
  const fileInputRef = React.createRef(); // Create a ref for the file input
  if (PostLoading) {
    return (
      <div>
        <Loader />
      </div>
    );
  }

  const handleEditCoverPhoto = () => {
    setUploadedPhoto(null);
    dispatch(setCoverPhotoUrl(null));
  };

 
//   console.log({
//      HeaderTitle,
    
// firstHeaderBlock
//   })

 
  return (
   <div  className='BlogWrapper'>


<div className="post-wrapper1">
      {!openPreview && (
        <div>
          <div className="nav-wrapper">
            <NavBlog />
          </div>
          <div className="post-publish-wrapper">
            <div className="post-publish">
              <div className="tooltipblog"></div>
              <button
                className="publish-btn"
                onClick={(event) => {
                  setPreview((current) => !current);
                  dispatch(setCategories(selectedCategory));
                }}
              >
                publish
              </button>
            </div>
          </div>

          <div className="post-edit">
            <div className="post-text-edit">
              <div className="post-cover-photo">
                <span
                  className="edit-cover-photo"
                  onClick={handleEditCoverPhoto}
                >
                  <FaEdit />
                </span>
                {!uploadedPhoto && !postsdata?.coverImgUrl && !coverImg ? (
                  // Conditionally render based on uploadedPhoto state
                  <>
                    <h2 className="cover-photo-h2">Add cover photo here</h2>
                    <h3 className="cover-photo-h3">
                      We recommend uploading or dragging in an image that is
                      1920x1080 pixels
                    </h3>

                    <div className="post-edit-cover-upload">
                      <input
                        id="file"
                        type="file"
                        onChange={handleFileChange}
                        style={{ display: "none" }}
                        ref={fileInputRef}
                      />
                      <button
                        className="upload-cover-p"
                        onClick={handleUploadButtonClick}
                      >
                        {uploading ? (
                          <BottonLoader />
                        ) : (
                          "Upload from your computer"
                        )}
                      </button>
                    </div>
                  </>
                ) : // Ensure postsdata and postsdata.coverImgUrl are defined before rendering the image
                postsdata?.coverImgUrl ? (
                  <img
                    src={postsdata.coverImgUrl}
                    alt="Cover"
                    className="uploaded-cover-photo"
                    style={{
                      width: "100%",
                      maxHeight: "308px",
                      objectFit: "contain",
                    }}
                  />
                ) : (
                  <img
                    src={coverImg || uploadedPhoto}
                    alt="Cover"
                    className="uploaded-cover-photo"
                    style={{
                      width: "100%",
                      maxHeight: "308px",
                      objectFit: "contain",
                    }}
                  />
                )}
              </div>
              <div className="post-edit-content">
                {/* <div className="bog-title">
                  <textarea
                    name="text"
                    rows="5"
                    cols="10"
                    wrap="soft"
                    placeholder={title ? title : "ADD TITLE"}
                    onChange={handtitleChange}
                  ></textarea>
                </div> */}
                <div className="start-typing">
                  <div className="app-content" style={{ width: "100%" }}>
                    <Editor
                      data={
                        postData && postData.rawdata ? postData.rawdata : data
                      }
                      setData={setData}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="post-side-bar">
              <div className="post-settings">
                <h3 className="post-settings-h3">Post settings</h3>
              </div>
              <div className="side-settings">
                <div className="post-categories">
                  <div className="post-settings-wrapper">
                    <p className="post-setting-label">categories</p>
                    <div onClick={handleClick} className="post-settings-arrow">
                      <SlArrowUp />
                    </div>
                  </div>
                  <div className="divider"></div>
                  {openCategory && (
                    <ul className="categories-ul">
                      <div className="searchCategory">
                        <div className="inputWrapper">
                          <input
                            className="input-cat"
                            type="text"
                            onChange={handleCategoryChange}
                            placeholder="search"
                          />
                          <button
                            className="cat-btn"
                            onClick={handleAddCategoryClick}
                            style={{
                              display: "flex",
                              padding: "8px 23px",
                              justifyContent: " center",
                              alignItems: "center",
                              gap: "10px",
                              color: "#fff",
                              borderRadius: "5px",
                              background: "#3FB871",
                              border: " none",
                            }}
                          >
                            Add
                          </button>
                        </div>
                        {filteredCategories.length > 0 && (
                          <ul className="suggestions">
                            {searchItem.length !== 0 &&
                              filteredCategories.map((category, index) => (
                                <li
                                  key={index}
                                  onClick={() =>
                                    handleSuggestionClick(category)
                                  } // Add on suggestion click
                                  style={{
                                    cursor: "pointer",

                                    listStyle: "none",
                                  }}
                                >
                                  {category}
                                </li>
                              ))}
                          </ul>
                        )}
                        <div>
                          <ul
                            className="selectedCategory"
                            style={{ listStyleType: "none", padding: 0 }}
                          >
                            {selectedCategory.map((category, index) => (
                              <li
                                key={index}
                                style={{
                                  padding: "4px 8px", // Horizontal padding for better button appearance
                                  borderRadius: "30px",
                                  border: "1px solid #ccc",
                                  backgroundColor: "#fff",
                                  cursor: "pointer",
                                  fontSize: "12px",
                                  width: "auto",
                                  display: "inline-block", // Make it inline-block to auto-adjust the width
                                  // Optional spacing below for multi-line support
                                }}
                              >
                                {category}
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </ul>
                  )}
                  <div></div>
                </div>
                <div>
                  {/* <div className='post-settings-wrapper'>
                                        <p className='post-setting-label'>Schedule Publish Date</p>
                                        <div ><SlArrowUp onClick={(event) => setPublish(current => !current)} /></div>
                                    </div>
                                    <div className='divider'></div>
                                    {openPublish && (
                                        <div className="post-radio">

                                            <label>    <input
                                                id='label-post'
                                                type="radio"
                                                value="automatic"
                                                checked={selectedOption === 'automatic'}
                                                onChange={handleOptionChange}
                                            />
                                                Automatic
                                            </label>
                                            <label>
                                                <input
                                                    id='label-post'
                                                    type="radio"
                                                    value="schedule"
                                                    checked={selectedOption === 'schedule'}
                                                    onChange={handleOptionChange}
                                                />
                                                Schedule
                                            </label>
                                        </div>
                                    )}
                                    {selectedOption === 'schedule' && openPublish && (
                                        <div>
                                            <div className='display-shedule'> <div className='selected-date-time'>
                                                <p>{selectedDate.toDateString()}</p>

                                            </div>
                                                <div className='selected-time'>
                                                    <p>10.46 AM</p>

                                                </div></div>
                                            <Calendar onChange={handleDateChange} value={value} />
                                        </div>
                                    )} */}
                  <div>
                    {/* <div className='post-settings-wrapper'>
                                            <p className='post-setting-label'>Comments</p>
                                            <div ><SlArrowUp onClick={(event) => setComment(current => !current)} /></div>

                                        </div> */}
                    {/* <div className='divider'></div> */}
                    {/* {openComment && (
                                            <div className="post-radio">
                                                <label>
                                                    <input
                                                        id='label-post'
                                                        type="radio"
                                                        value="Allow comments"
                                                        checked={commentOption === 'Allow comments'}
                                                        onChange={handleCommentChange}
                                                    />
                                                    Allow comments
                                                </label>
                                                <label>
                                                    <input
                                                        id='label-post'
                                                        type="radio"
                                                        value="Dont allow comments"
                                                        checked={commentOption === 'Dont allow comments'}
                                                        onChange={handleCommentChange}

                                                    />
                                                    Don't allow comments
                                                </label>
                                            </div>
                                        )} */}
                  </div>
                </div>

                <div>
                  {" "}
                  <div className="post-settings-wrapper">
                    <p className="post-setting-label">Author Name</p>
                    <div>
                      <SlArrowUp />
                    </div>
                  </div>
                  <div className="divider"></div>
                  <div className="author-name">
                    <p>{username}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <div>
        {openPreview && (
          <Preview
            rawdata={data}
            setPreview={setPreview}
            setData={setData}
            postId={postid}
            updatedPost={data}
            categories={selectedCategory}
          />
        )}
      </div>
      <ToastContainer />
    </div>
   </div>
  );
}
