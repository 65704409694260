import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { PostHeroCard, PostCard } from '../../Features/Post.Card';
import userPic from '../../Components/Assets/Blog/Ellipse 1.svg'
import './index.css';
import { categories } from '../../Components/blogPost/categoryList'
import dayjs from 'dayjs';
import { useNavigate, useParams } from 'react-router';
import { farmsellApi } from '../../Api/famsellAPi';
import { CiSearch } from 'react-icons/ci';
import { setPostsData, setRecentPosts } from '../../Components/Slices/Posts.slice';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { RecentPosts } from '../../Features/postFeatures';
import OuterNavBar from '../OuterNavBar';
import Footer from '../Footer';
import { setLoading } from '../../Components/Slices/Loadind.slice';
import useFetchPosts from '../../Hooks/useFetchPosts';
import Loader from '../../utils/Loader';
import SeoComponent from '../../Features/SeoComponent';
export default function Category() {
  const dispatch = useDispatch();
  const [post, setPost] = useState([]);
  const [posts, setPosts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const navigate = useNavigate()

  const { fetchPosts } = useFetchPosts('/post/recent', {
    params: {
      currentPage,
      pageSize: 6,
    }
  })
  const {CategoryName} = useParams()


  const fetchCategoryPosts = ()=>{
    const res = farmsellApi.get(`posts/category/:${CategoryName}`, {
        params: {
          currentPage,
          pageSize: 6,
        }
      })
  }
  const { fetchData } = useFetchPosts('/posts', {
    params: {
      currentPage,
      pageSize: 6,
    }
  })
  const BlogPosts = useSelector((state) => state.BlogPosts.posts);
  const recentPosts = useSelector((state) => state.BlogPosts.recentPosts);
  const loading = useSelector((state) => state.loading.loading);
  const rentLoading = useSelector((state)=> state.BlogPosts.rentPostsLoading)
  const postLoading = useSelector((state)=> state.BlogPosts.postsLoading)
  const [categoryPosts, setCategoryPosts] = useState([]);
  console.log("recent postss", recentPosts)
  const [query, setQuery] = useState('');
  const [selectedValue, setSelectedValue] = useState("");
  useEffect(() => {
    fetchPosts();
    fetchData();
  }, [rentLoading,postLoading]);
  useEffect(() => {
    fetchPostsByCategory();
  }, [CategoryName, currentPage, query]); // Trigger fetching when category, page, or search query changes

  const fetchPostsByCategory = async () => {
    try {
      const res = await farmsellApi.get(`/category/${CategoryName}`, {
        params: {
          currentPage,
          pageSize: 6,
          search: query, // Include the search query in the request
        },
      });
      setCategoryPosts(res.data);
      setTotalPages(res.data.totalPages);
      console.log("Response", res.data);
    } catch (error) {
      console.log('Error fetching posts by category:', error);
    }
}
    useEffect(() => {
        if (categoryPosts) {
          const formattedPosts = categoryPosts.map((post) => {
            const parsedData = post.data ? JSON.parse(post.data)?.rawdata : null;
            const parsedCategories = post.categories ? JSON.parse(post.categories).categories : [];
    
            return {
              key: post.id,
              data: parsedData,
              description: post.description,
              title: post.title,
              metaTitle: post.metaTitle,
              metadescription: post.metadescription,
              Categories: parsedCategories,
              coverPhoto: post.coverImgUrl,
              profilePhoto: post.user?.UserPic || userPic,
              username: post.user?.name || 'Unknown Author',
              Created: dayjs(post.createdAt).format('MMMM DD, YYYY'),
            };
          });
          setPost(formattedPosts);
        }
      }, [categoryPosts]);

  const handlePageChange = (page) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
    }
  };
  const handleChangeQuery = (e) => {
    setQuery(e.target.value);

  };
  const filteredPosts = posts.filter(post => post.title.toLowerCase().includes(query.toLowerCase()));
  const handleCategoryClick = (categoryName) => {
    navigate(`/category/${categoryName}`);
  };
  console.log("categoryName",CategoryName)
  return (
    <div className="BlogWrapper">
      <div className='blog-wrapper-posts'>
        <OuterNavBar />

        <div className='header-wrapper'>
        <div className='categories'>
        <select
              value={selectedValue}
              onChange={(e) => {
                const selectedCategory = e.target.value;
                setSelectedValue(selectedCategory);
                handleCategoryClick(selectedCategory); // Trigger navigation
              }}
              style={{ overflow: "hidden", height: "6vh" }}
            >
              {categories.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
</div>

          <div className='search-header'>
            {/* <input type='text' className='form-control' placeholder='Hi, what would you want to read today?' value={query}  onChange={handleChangeQuery}/>
            
              <button className='search-icon'>
                <CiSearch />
              </button>
             */}
          </div>
        </div>
        <div className='post-hero-wrapper-h3'>
          <h2>{CategoryName}</h2>
           
        </div>
        <div className='grid-container'>
          {loading ? (
            <Loader />
          ) : (
            filteredPosts.map((postItem) => <PostCard key={postItem.key} post={postItem} />)
          )}
        </div>
        <div className='pagination'>
          <button onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1} className='page-button'>
            &larr;
          </button>
          {[...Array(totalPages)].map((_, i) => (
            <button
              key={i}
              onClick={() => handlePageChange(i + 1)}
              className={`page-button ${currentPage === i + 1 ? 'active' : ''}`}
            >
              {i + 1}
            </button>
          ))}
          <button onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages} className='page-button'>
            &rarr;
          </button>
        </div>


      </div>
      <Footer />
    </div>
  );
}
