import axios from "axios";


export const farmsellApi = axios.create({
    baseURL:'https://blog.farmsell.net',
    headers:{
"Content-Type":"application/json"
    },
})

export const tokenConfig= ()=>{

}