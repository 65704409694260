import React from "react";
import { useNavigate } from "react-router";
import Slider from "react-slick";

import CoverIcon from "../Components/Assets/Blog/Image.svg";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import SeoComponent from "./SeoComponent";
export function PostHeroCard({ post }) {
  const navigate = useNavigate();
  const settings = {
    dots: true,
    infinite: true,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 6000,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    cssEase: "linear",
  };
  const posts = [post];
  const item = [post];
  console.log("itmes", item);
  console.log("post", post);
  const handleNavigation = (title) => {
    const urlFriendlyTitle = encodeURIComponent(
      title.toLowerCase().replace(/ /g, "-")
    );
    navigate(`/post/${urlFriendlyTitle}`);
  };
  console.log(Object.entries(post));
  return (
    <Slider {...settings}>
      {post && post.length > 0
        ? post.map((post, index) => (
            <div key={index} className="main-wrapper">
              <div className="hero-wrapper-div">
                <div className="hero-cover-image">
                  <img
                    src={
                      post.coverPhoto ? (
                        post.coverPhoto
                      ) : (
                        <img
                          src={CoverIcon}
                          alt="Cover"
                          className="uploaded-cover-photo"
                          style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "contain",
                          }}
                        />
                      )
                    }
                    alt="Cover"
                  />
                </div>
                <div
                  className="post-details-hero-card"
                  onClick={() => handleNavigation(post.title)}
                >
                  <h2>{post.title}</h2>
                  <div className="details-wrapper-row">
                    <img
                      src={
                        post.profilePhoto ? post.profilePhoto : "loading data"
                      }
                      alt="Author"
                    />
                    <div className="post-details-column">
                      <div className="post-details-hero">
                        <p className="author-name-hero">
                          {post.author ? post.author : "Williams"}
                        </p>
                        <div className="post-details">
                          <p className="date-of-post">
                            {post.date ? post.date : "May 03, 2014"}
                          </p>
                          <span className="green-dot"></span>
                          <p className="post-read-period">
                            {post.readTime ? post.readTime : "3 min read"}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))
        : null}
    </Slider>
  );
}
export function PostCard({ post }) {
  const navigate = useNavigate();
  const handleNavigation = (title) => {
    const urlFriendlyTitle = encodeURIComponent(
      title.toLowerCase().replace(/ /g, "-")
    );
    navigate(`/post/${urlFriendlyTitle}`);
  };
  console.log(post);
  const posts = [post];
  console.log(posts);
  return (
    <>
      {posts && posts.length > 0 ? (
        posts.map((postItem, key) => {
          return (
            <div
              key={key}
              className="card"
              onClick={() => handleNavigation(postItem.title)}
            >
              <SeoComponent
                title={postItem.metaTitle ?? null}
                description={postItem.metadescription ?? null}
              />

              <img
                src={postItem.coverPhoto || CoverIcon}
                alt="Cover"
                className="card-image"
              />

              <div className="card-content">
                <h3 className="card-title">
                  {postItem.title.length > 40
                    ? postItem.title.slice(0, 40) + "..."
                    : postItem.title || "Title of the card"}
                </h3>
                <p className="card-description">
                  {postItem.description || "Description of the card"}
                </p>
              </div>
              <div className="divider"></div>
              <div className="card-footer">
                <img
                  src={postItem.profilePhoto || "loading data"}
                  alt="Profile"
                  className="author-image"
                />
                <div className="author-info">
                  <p className="author-name-card">
                    {postItem.username || "Author Name"}
                  </p>

                  <p className="card-date">
                    {postItem.Created || "Date of Post"}
                  </p>
                </div>
              </div>
            </div>
          );
        })
      ) : (
        <div>No posts available</div>
      )}
    </>
  );
}
